import { ChainId, Currency, Token } from '@uniswap/sdk-core'
import { FeeAmount, Pool } from '@uniswap/v3-sdk'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useMemo } from 'react'

import { useAllCurrencyCombinations } from './useAllCurrencyCombinations'
import { PoolState, usePools, usePools2 } from './usePools'

/**
 * Returns all the existing pools that should be considered for swapping between an input currency and an output currency
 * @param currencyIn the input currency
 * @param currencyOut the output currency
 */
export function useV3SwapPools(
  currencyIn?: Currency,
  currencyOut?: Currency
): {
  pools: Pool[]
  loading: boolean
} {
  const { chainId } = useActiveChainId()

  const allCurrencyCombinations = useAllCurrencyCombinations(currencyIn, currencyOut)

  const allCurrencyCombinationsWithAllFees: [Token, Token, FeeAmount][] = useMemo(
    () =>
      allCurrencyCombinations.reduce<[Token, Token, FeeAmount][]>((list, [tokenA, tokenB]) => {
        return chainId === ChainId.MAINNET
          ? list.concat([
              [tokenA, tokenB, FeeAmount.LOW],
              [tokenA, tokenB, FeeAmount.MEDIUM],
              [tokenA, tokenB, FeeAmount.HIGH],
            ])
          : list.concat([
              [tokenA, tokenB, FeeAmount.LOWEST],
              [tokenA, tokenB, FeeAmount.LOW],
              [tokenA, tokenB, FeeAmount.MEDIUM],
              // [tokenA, tokenB, FeeAmount.HIGH],
            ])
      }, []),
    [allCurrencyCombinations, chainId]
  )
  const pools = usePools(allCurrencyCombinationsWithAllFees)
  return useMemo(() => {
    return {
      pools: pools
        .filter((tuple): tuple is [PoolState.EXISTS, Pool] => {
          return tuple[0] === PoolState.EXISTS && tuple[1] !== null
        })
        .map(([, pool]) => pool),
      loading: pools.some(([state]) => state === PoolState.LOADING),
    }
  }, [pools])
}

export function useV3TradePools(
  currencyIn?: Currency,
  currencyOut?: Currency
): {
  pools: { pool: Pool; poolAddr: string }[]
  loading: boolean
} {
  const { chainId } = useActiveChainId()

  const [tokenA, tokenB] = chainId ? [currencyIn?.wrapped, currencyOut?.wrapped] : [undefined, undefined]

  const allCurrencyCombinationsWithAllFees: [Token, Token, FeeAmount][] = useMemo(() => {
    if (!tokenA || !tokenB) return []

    return [
      [tokenA, tokenB, FeeAmount.LOW],
      [tokenA, tokenB, FeeAmount.MEDIUM],
      [tokenA, tokenB, FeeAmount.HIGH],
      [tokenA, tokenB, FeeAmount.LOWEST],
    ]
  }, [tokenA, tokenB])

  const pools = usePools2(allCurrencyCombinationsWithAllFees)

  return useMemo(() => {
    return {
      pools: pools
        .filter((tuple): tuple is [PoolState.EXISTS, { pool: Pool; poolAddr: string }] => {
          return tuple[0] === PoolState.EXISTS && tuple[1] !== null
        })
        .map(([, data]) => data),
      loading: pools.some(([state]) => state === PoolState.LOADING),
    }
  }, [pools])
}
